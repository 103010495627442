import React from 'react'
import * as _ from "lodash";
import {projectCurrency, showContextLinks} from "../../properties";
import {EntityLinksDropdown} from "../../EntityLinks";
import {Carousel, Descriptions, Image, Row, Statistic} from "antd";
import {Col} from "antd/es";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";

export const beautifyKey = key => _.startCase(key.split('_').join(" "))

export function HeaderInfo ({item, handleModalOpen, showEdit, headerColumns, images,usage}) {

    const getLabel = field => {
        const mapping = {
            'price': `Price per Unit (${projectCurrency})`,
            'cl_name': item.type === 'material' ? 'Material Class' : 'Asset Category',
            'material': item.type === 'material' ? 'Material' : 'Equipment',
            'usage': 'Forecast Demand',
            'mat_type': 'Material Type',
            'mat_group': 'Material Group',
            'contract_lead_time': 'Contract Delivery Time'
        }
        return mapping[field] || beautifyKey(field)
    }

    const getImages = () => {
        let arr =[...images].sort()
        arr.sort(function(a, b) {
                var keyA = a.active,
                    keyB = b.active;
                if (keyA > keyB) return -1;
                if (keyA < keyB) return 1;
                return 0;
            });
        return arr
    }

    const editing = <a onClick={handleModalOpen}>
            {(images && images.length > 0) ? <img alt='edit-image' src="/images/edit-user-blue.png"/> : 'Add image'}
        </a>

    const getKeyValue = key => {
        if (key === 'part_number') {
            return <a href={`https://www.google.com/search?q=${item[key]}`} target="_blank"
                      rel="noopener noreferrer">{item[key]}</a>
        } else if (key === 'usage') {
            return Math.round(usage / 4 * 100) / 100
        }else if (key === 'price') {
            return <Statistic valueStyle={{fontSize: 14}} value={parseInt(item[key] * 100 || 0) / 100}/>
        }
        return item[key]
    }

    return <>
        <h3 className='text-center'>Item: <b>{item.operator} {item.material}</b>
            {showContextLinks && <EntityLinksDropdown operator={item.operator} sortCode={item.sort_field} material={item.material} type={item.type}/>}
        </h3>
        <Row>
            <Col span={15}>

                <Descriptions size='small' bordered>
                    {headerColumns.map(field => {
                        return <Descriptions.Item span={24} labelStyle={{width: '20%'}} label={<b>{getLabel(field)}</b>}>{getKeyValue(field)}</Descriptions.Item>
                    })}
                </Descriptions>
            </Col>
            <Col span={9}>
                {images && images.length > 0 && <Carousel arrows={true} style={{alignItems: 'center'}} prevArrow={<LeftOutlined />} nextArrow={<RightOutlined />} >
                    {getImages().map(v => <Image preview={false} src={v.image} height={320} width={400} style={{padding: "0 auto"}} />)}
                </Carousel>}
                <div style={{display: 'inline'}}>
                    {showEdit && editing}
                </div>
            </Col>
        </Row>
    </>

}
